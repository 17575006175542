const AutoplayVideo = ($ => {
  class AutoplayVideo {
      constructor(btnSelector, videoWrapSelector, videoBtnWrapSelector) {
          this.$btn = $(btnSelector);
          this.videoWrapSelector = videoWrapSelector;
          this.videoBtnWrapSelector = videoBtnWrapSelector;
      }

      onBtnClick() {
          const $activeVideoWrap = $(`${this.videoWrapSelector}.active`);
          const $activeVideos = $activeVideoWrap.find("video");
          $activeVideos.each(function () {
              this.play();
          });
          $activeVideoWrap.find("video").prop("muted", true);
          $(this.videoBtnWrapSelector).addClass("active");
      }

      init() {
          this.$btn.click(this.onBtnClick.bind(this));
      }
  }

  return AutoplayVideo;
})(window.jQuery);

(($) => {
  const desktopAutoplay = new AutoplayVideo(".accordion-btn", ".video-wrap", ".video-btn-wrap");
  desktopAutoplay.init();

  const mobileAutoplay = new AutoplayVideo(".mobile-accordion-btn", ".mobile-video-wrap", ".mobile-video-btn-wrap");
  mobileAutoplay.init();
})(window.jQuery);
