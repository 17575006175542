const Menu = (($) => {
    class Menu {
      constructor(node, altbutton) {
        this.node = node;
        this.$node = $(node);
        this.altbutton = altbutton;
        this.$altbutton = $(altbutton);
        this.$node.attr('data-menu', true);
        this.$group = $('[data-menu-group]', this.$node);
//        this.$triggers = $('[data-menu-action]', this.$node);//.add(altbutton);
        this.$triggers = $('[data-menu-action]', this.$altbutton);
        this.$drawer = $('[data-menu-drawer]', this.$node);
        this.$overlay = $('[data-menu-overlay]', this.$node);
        this.$language = $('[data-menu-language]', this.$node);

        // Bind event handlers to the instance
        this.onTriggerClick = this.onTriggerClick.bind(this);
        this.onAnchorClick = this.onAnchorClick.bind(this);
      }
    
      onTriggerClick(event) {
        let target = $(event.currentTarget);
        let action = target.data('menu-action');

        switch(action){
            case 'menu':
                this.open();
                break;
            case 'close':
                this.close();
                break;
        }
      }
      
      // Function to remove active classes
      removeActiveClasses() {
        this.$group.removeClass('active');
        this.$overlay.removeClass('active');
        this.$drawer.removeClass('active');
        this.$language.removeClass('active');
        this.$altbutton.removeClass('active');
        $('body').removeClass('overflow-hidden');
      }

      // Event handler for anchor tag click
      onAnchorClick() {
        this.removeActiveClasses();
      }

      close() {
        this.$altbutton.removeClass('active');
        this.removeActiveClasses();
        $('body').removeClass('overflow-hidden');
      }

      open() {
        $(document).scrollTop(0);
        $('body').addClass('overflow-hidden');

        this.$altbutton.addClass('active');
        this.$group.addClass('active');
        this.$overlay.addClass('active');
        this.$drawer.addClass('active');
        this.$language.addClass('active');
        
        this.$drawer.scrollTop(0);
        
      }

      init() {
        this.$triggers.on('click', this.onTriggerClick);
        // Add click event listener to anchor tags inside .menu-static
        $('.static-menu a').on('click', this.onAnchorClick);
      }
    }
  
    return {
       init({ selector, altselector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             let altbutton = $(altselector);
             const module = new Menu(node, altbutton);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    Menu.init({
       selector: '[data-menu]',
       altselector: '[data-menu-control]',
    });

    $(document).on('ajaxComplete', ()=>{
      Menu.init({
        selector: '[data-menu="false"]',
      });
    })
 })(window.jQuery);