import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

(function() {
    let currentActiveSection = null;

    ScrollTrigger.matchMedia({
        "(min-width: 1024px)": function () {
            gsap.defaults({overwrite: 'auto'});
            gsap.set("[data-features-pin] > *", {xPercent: 0, yPercent: -50});
        

            gsap.utils.toArray('[data-marker-content]').forEach(function(contentCard, index) {
                
                let tl = gsap.timeline({

                    // yes, we can add it to an entire timeline!
                    scrollTrigger: {
                        trigger: contentCard,
                        //   pin: true,   // pin the trigger element while active
                        start: "20% 50%", // when the top of the trigger hits the top of the viewport
                        end: "70% 50%", // end after scrolling 500px beyond the start
                        // scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                        toggleActions: "play none play none",
                        markers: false,
                        id: "arrive_" + index,
                        onEnter: () => {
                            jQuery('[data-marker-content]').removeClass('active');
                            jQuery(contentCard).addClass('active');

                            contentCard.content = document.querySelector(`#${contentCard.dataset.markerContent}`);
                            let dir = 1;

                            // If we've got a section to animate in then...
                            if(contentCard.content && currentActiveSection != contentCard.content) {

                                // debugger;

                                // animate out the old
                                if(currentActiveSection) {
                                    jQuery(currentActiveSection).removeClass('active');
                                    gsap.fromTo(currentActiveSection, {}, {y: -75*dir, duration: 0.25, autoAlpha: 0, delay:0});
                                }
                                
                                // and animate in the new
                                currentActiveSection = contentCard.content;
                                jQuery(currentActiveSection).addClass('active');
                                gsap.fromTo(currentActiveSection, {autoAlpha: 0, y: 75*dir}, {duration: .5, autoAlpha: 1, y:0});
                            }
                        },
                        onLeave: () => {
                        },
                        onEnterBack: () => {
                            jQuery('[data-marker-content]').removeClass('active');
                            jQuery(contentCard).addClass('active');

                            contentCard.content = document.querySelector(`#${contentCard.dataset.markerContent}`);
                            let dir = -1;

                            // If we've got a section to animate in then...
                            if(contentCard.content && currentActiveSection != contentCard.content) {

                                // debugger;

                                // animate out the old
                                if(currentActiveSection) {
                                    jQuery(currentActiveSection).removeClass('active');
                                    gsap.fromTo(currentActiveSection, {}, {y: -75*dir, duration: 0.25, autoAlpha: 0, delay:0});
                                }
                                
                                // and animate in the new
                                currentActiveSection = contentCard.content;
                                jQuery(currentActiveSection).addClass('active');
                                gsap.fromTo(currentActiveSection, {autoAlpha: 0,y: 75*dir}, {duration: .5, autoAlpha: 1, y:0});
                            }
                        },
                        onLeaveBack: () => {
                        },
                    }
                });
            
            })
        }
    });

    gsap.utils.toArray('[data-features-animation]').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.to(element, {
                    scrollTrigger: {
                        trigger: "[data-features-animation]",
                        start: "top top",
                        end: "bottom bottom",
                        pin: "[data-features-pin]",
                    }
                });
            }
        });
    });
     
})();

