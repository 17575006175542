const Form = (($) => {
    class Form {
        constructor(node) {
            this.node = node;
            this.$node = $(node);
        }

        setFormConditions() {
            let country = $('#eq_country').val();
            if(country !== 'United States') {
                this.setNotRequired('#eq_state');
                this.setNotRequired('#eq_postal_code');

                this.resetValue('#eq_state');
                this.resetValue('#eq_postal_code');
                this.resetValue('#eq_address1');
                this.resetValue('#eq_address2');

                $('#eq_state, #eq_state + label').hide();
                $('#eq_postal_code, #eq_postal_code + label').hide();
                $('#eq_address1, #eq_address1 + label').hide();
                $('#eq_address2, #eq_address2 + label').hide();
            } else {
                this.setRequired('#eq_state');
                this.setRequired('#eq_postal_code');

                $('#eq_state, #eq_state + label').show();
                $('#eq_postal_code, #eq_postal_code + label').show();
                $('#eq_address1, #eq_address1 + label').show();
                $('#eq_address2, #eq_address2 + label').show();
            }
        } 

        resetValue(element) {
            $(element, this.$node).val(null);
        }

        setRequired(element) {
            let $element = $(element, this.$node);
            $element.attr('required', 'required');
            $element.attr('aria-required', true);
            if($element.is('select') && $element.val() === null){
                $element.val($('option:first',$element).val());
            }
        }

        setNotRequired(element) {
            let $element = $(element, this.$node);
            $element.removeAttr('required');
            $element.attr('aria-required', false);
        }

        validateForm(event) {
            var checkboxes = $('.checkboxes.required', this.$node);
            var inputs = checkboxes.find('input[type="checkbox"]');
            
            if(checkboxes.find('input:checked').length === 0) {
                event.preventDefault();                
                inputs.get(0).setCustomValidity('Please select at least one option.');
                inputs.get(0).reportValidity();
            }
        }

        setCheckboxValidity(event) {
            var inputs = $('.checkboxes.required input[type="checkbox"]');
            inputs.get(0).setCustomValidity('');
        }

        onConditionalChange(event) {
            this.toggleConditional(event.target);
        }

        toggleConditional(target) {
            target = $(target);
            let conditionalValue = target.data('conditional-value');
            let conditionalTarget = target.data('conditional-target');
            let value = target.val();

            console.log(value);
            
            if(
                ( value === conditionalValue && !target.is('[type="checkbox"]')) ||
                (value === conditionalValue && target.is('[type="checkbox"]') && target.is(':checked'))
            ) {
                $(`#${conditionalTarget}`).slideDown();
                this.setRequired($('input, select, textarea', `#${conditionalTarget}`))
            } else {
                $(`#${conditionalTarget}`).slideUp();
                this.setNotRequired($('input, select, textarea', `#${conditionalTarget}`))
            }
        }

        onSelectChange(event) {
            this.toggleOther(event.target);
        }

        toggleOther(target) {
            target = $(target);

            let otherId = target.data('show-other');
            let value = target.val();
            console.log(target.is('[checked]'))
            if(
                value === 'other' || 
                (value === 'Other' && target.is('[type="checkbox"]') && target.is(':checked')) 
            ) {
                $(`#${otherId}`).slideDown();
                this.setRequired($('input, select, textarea', `#${otherId}`))
            } else {
                $(`#${otherId}`).slideUp();
                this.setNotRequired($('input, select, textarea', `#${otherId}`))
            }
        }


        init() {
            this.setFormConditions();
            this.$node.on('change', this.setFormConditions.bind(this));
            $('.checkboxes.required input[type="checkbox"]', this.$node).on('change', this.setCheckboxValidity.bind(this));

            $('[data-conditional-value][data-conditional-target]').map((index, target) => {
                this.toggleConditional(target)
            });

            $('[data-conditional-value][data-conditional-target]').on('change', this.onConditionalChange.bind(this));


            this.$node.on('submit', this.validateForm.bind(this));
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new Form(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    Form.init({
        selector: "#eq_form_email_sign_up",
    });
})(window.jQuery);
