class ToggleLanguageMenu {
    constructor() {
      this.toggleElement = document.querySelector('[data-toggle]');
      this.body = document.body;
      this.init();
    }
  
    init() {
      this.toggleElement?.addEventListener('click', () => {
        this.toggleBodyClass();
      });
    }
  
    toggleBodyClass() {
      const toggleData = JSON.parse(this.toggleElement.getAttribute('data-toggle'));
      const targetElement = document.querySelector(toggleData.target);
      const isActive = targetElement.classList.contains('active');
  
      if (isActive) {
        this.body.classList.add('overflow-hidden');
      } else {
        this.body.classList.remove('overflow-hidden');
      }
    }
  }
  
  // Initialize the ToggleLanguageMenu when the DOM is fully loaded
  document.addEventListener('DOMContentLoaded', function() {
    new ToggleLanguageMenu();
  });
  