import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

(function() {
    gsap.utils.toArray('[data-hero-animation] .js-hero-container').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.to(element, {
                    scrollTrigger: {
                        trigger: "body",
                        start: 'top top',
                        end: "+=120%",
                        scrub: true,
                    },
                    opacity: 0
                });
            }
        });
    });
    
    
    gsap.utils.toArray('[data-hero-animation] .js-hero-image').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.to(element, {
                    scrollTrigger: {
                        trigger: "body",
                        start: 'top top',
                        end: "+=100%",
                        scrub: .75,
                    },
                    scale: 12,
                    translateY: "180%"
                });
            }
        });
    });
    
    gsap.utils.toArray('[data-hero-animation] .js-hero-title').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.to(element, {
                    scrollTrigger: {
                        trigger: "body",
                        start: 'top top',
                        end: "+=100%",
                        scrub: .5,
                    },
                    translateY: "200%"
                });
            }
        });
    });
    
    gsap.utils.toArray('[data-parallax-animation]').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.to(element, {
                    scrollTrigger: {
                        trigger: element,
                        start: 'top center',
                        end: "+=50%",
                        scrub: .75,
                    },
                    padding: 0
                });
            }
        })
    })
    
    gsap.utils.toArray('.js-animation-blur').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                    gsap.set(element, {
                        filter:"blur(25px)"
                    });
                    gsap.to(element, {
                        filter: "blur(0px)",
                        scrollTrigger: {
                            trigger: element,
                            start: 'center bottom',
                            end: "+=50%",
                            scrub: .5,
                        },
                    });
        
            }
        })
    });
    
    gsap.utils.toArray('.js-animation-shrink').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.set(element, {
                    scale: 1.1,
                    translateY: "5%"
                });
                gsap.to(element, {
                    scale: 1,
                    translateY: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'center bottom',
                        end: "+=50%",
                        scrub: 1,
                    },
                });
            }
        })
    });
    
    gsap.utils.toArray('.js-animation-fade-up').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.set(element, {
                    opacity: 0,
                    translateY: "15%"
                });
                gsap.to(element, {
                    opacity: 1,
                    translateY: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'center 75%',
                        end: '+=150px',
                    },
                });
            }
        })
    });
    
    gsap.utils.toArray('.js-animation-fade-right').forEach((element, index) => {
        ScrollTrigger.saveStyles(element);
        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                gsap.set(element, {
                    opacity: 0,
                    translateX: "-15%"
                });
                gsap.to(element, {
                    opacity: 1,
                    translateX: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'center 75%',
                        end: '+=150px',
                    },
                });
            }
        })
    });
})();

