const Alert = (($) => {
    class Alert {
        constructor(node) {
            this.node = node;
            this.$node = $(node);
            this.$close = $('[data-alert-close', this.$node);
            this.$navigation = $('[data-global-nav]');
            this.initialTop = parseInt(this.$navigation.css('top'), 10);
        }

        reposition() {
            const scrollTop = $(document).scrollTop();
            const alertHeight = parseFloat(this.$node[0].scrollHeight);
            const top = Math.max(0, alertHeight - scrollTop);
            this.$navigation.css({'top': top + this.initialTop + 'px'});
        }

        close() {
            this.$node.slideUp('slow', ()=>{
                this.reposition();
            });

            var timeToAdd = 1000 * 60 * 60 * 24 * 1 * 4 * 6;
            var date = new Date();
            var expiryTime = parseInt(date.getTime()) + timeToAdd;
            date.setTime(expiryTime);
            var utcTime = date.toUTCString();
            document.cookie = "haven_alert_seen=1; expires=" + utcTime + ";";
        }

        init() {
            this.reposition();
            $(window).on('scroll resize', this.reposition.bind(this))
            this.$close.on('click', this.close.bind(this));
        }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new Alert(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    Alert.init({
       selector: '[data-alert]',
    });

    $(document).on('ajaxComplete', ()=>{
      Alert.init({
        selector: '[data-alert="false"]',
      });
    })
 })(window.jQuery);