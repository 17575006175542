const Header = (($) => {
    class Header {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
        this.$nav = $('[data-global-nav]');
        this.$slot = $('[data-subnav-slot]', this.$node);
        this.$fill = $('[data-subnav-fill').first();
      }

      initializeSlotFill() {
        this.$fill.contents().clone().appendTo(this.$slot);
        $(document).trigger('slotfill');
      }

      onWindowScroll() {
        let scrollTop = $(document).scrollTop();
        let scrollElement = $('.wp-block-basejump-interior-hero').first();
        let threshold = scrollElement.length ? scrollElement.scrollTop() + scrollElement.height() : 0;
        this.toggleNav(scrollTop > threshold);
      }

      toggleNav(enable) {
        this.$nav.toggleClass('active', enable);
        this.$slot.toggleClass('active', enable);
        if(!enable){
          $(document).trigger('slotfillclose');
        }
      }

      init() {
        this.onWindowScroll();
        this.initializeSlotFill();
        $(window).on('scroll', this.onWindowScroll.bind(this))
      }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new Header(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    Header.init({
       selector: '[data-header]',
    });

    $(document).on('ajaxComplete', ()=>{
      Header.init({
        selector: '[data-header="false"]',
      });
    })
 })(window.jQuery);