const Parallax = (($) => {
    class Parallax {
        constructor(node) {
            this.node = node;
            this.$node = $(node);
            this.parent = this.$node.parent();
        }

        setup() {
            if($(window).outerWidth() >= 1024){
                let scrollPosition =
                this.parent.height() +
                this.parent[0].getBoundingClientRect().top;
                let percentage = scrollPosition / this.parent.height() - 1;
                let translate = percentage * this.parent.height();
                this.$node.css({
                    transform: "translateY(" + translate + "px)",
                });
            } else {
                this.$node.removeAttr("style");
            }

        }

        init() {
            this.setup();
            $(window).on("scroll", this.setup.bind(this));
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new Parallax(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    Parallax.init({
        selector: "[data-parallax]",
    });
})(window.jQuery);
