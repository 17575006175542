const HowItWorks = (($) => {
    class HowItWorks {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
        this.$node.attr('data-howitworks', true);
        this.tabGroup = this.$node.data('tab-group');
        this.$button = $('[data-tab-id]', this.$node).filter((index, button)=>{
          return $(button).closest('[data-howitworks]').is(this.$node);
        });
      }
 
      onTabClick(e) {
          e.stopPropagation();
          
          let $button = $(e.currentTarget);
          //let $content = $('#' + $button.attr('aria-controls'), this.$node);

          let controlIds = $button.attr('aria-controls').split(' ');

          let $defaultcontent = $('#' + $button.attr('aria-controls-default'), this.$node);
          let $defaultmedia = $('#' + $button.attr('aria-controls-default') + '-media', this.$node);

          if ($button.hasClass('active')) {
            $('.active', this.$node).removeClass('active');
            $defaultcontent.addClass('active');
            $defaultmedia.addClass('active');
          } else {
            $('.active', this.$node).removeClass('active');
            $button.addClass('active');
            for (var i = 0; i < controlIds.length; i++) {
              var id = controlIds[i];
              var $content = $('#' + id, this.$node);
              $content.addClass('active');
              $content.find('[data-slick]').slick('reinit');
            }
          }
          
          $(window).trigger('arrive');
       }
  
      init() {
        this.$button.on('click', this.onTabClick.bind(this));
      }
    }
  
    return {
       init({ selector, context } = {}) {
 
          return jQuery(selector, context).map((index, node) => {
             const module = new HowItWorks(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
  
    HowItWorks.init({
       selector: '[data-howitworks]',
       context,
    });

    $(document).on('ajaxComplete', ()=>{
      HowItWorks.init({
        selector: '[data-howitworks="false"]',
        context,
      });
    })
 
 })(window.jQuery);

 export default HowItWorks;