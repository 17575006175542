const StickyNav = ($ => {
  class StickyNav {
    constructor(scrollThreshold) {
      this.scrollThreshold = scrollThreshold || 80;
      this.$document = $(document);
      this.$stickyNav = $(".sticky-nav");
      this.prevScrollPos = this.$document.scrollTop();
    }

    onScroll() {
      const currentScrollPos = this.$document.scrollTop();
      const scrollingDown = currentScrollPos > this.prevScrollPos;
      const isAtTop = currentScrollPos <= this.scrollThreshold;

      this.$stickyNav.toggleClass("scroll-up", !scrollingDown && !isAtTop);
      this.$stickyNav.toggleClass("scroll-down", scrollingDown && !isAtTop);

      this.prevScrollPos = currentScrollPos;
    }

    init() {
      this.$document.scroll(this.onScroll.bind(this));
      this.onScroll(); // Apply the class immediately in case the page is already scrolled on load.
    }
  }

  return StickyNav;
})(window.jQuery);

(($) => {
  const scrollThreshold = 80;
  const stickyNav = new StickyNav(scrollThreshold);
  stickyNav.init();
})(window.jQuery);