const CursorControl = (($) => {
    class CustomCursor {
        constructor(node) {
            this.node = node;
            this.$node = $(this.node);
            this.$enabledArea = $('[data-cursor-area]', this.$node);
            this.$customCursor = $('[data-cursor-pointer]', this.$node);
            this.$node.addClass('group');
            this.$node.removeClass('cursor-default');
            this.$customCursor.removeClass('active click');
        }

        replaceCursor(event) {
          const mouseY = event.clientY;
          const mouseX = event.clientX;

          this.$customCursor.css({'transform': `translate3d(${mouseX}px, ${mouseY}px, 0)`});
        }

        onMouseEnter(event) {
          this.$node.addClass('cursor-default');
          this.$customCursor.addClass('active');
        }

        onMouseDown(event) {
          this.$customCursor.addClass('click');
        }

        onMouseUp(event) {
          this.$customCursor.removeClass('click');
        }

        onMouseLeave(event) {
          this.$node.removeClass('cursor-default');
          this.$customCursor.removeClass('active');
        }

        init() {
            window.addEventListener('mousemove', this.replaceCursor.bind(this));
            this.$enabledArea.on('mouseenter', this.onMouseEnter.bind(this));
            this.$enabledArea.on('mousedown', this.onMouseDown.bind(this));
            this.$enabledArea.on('mouseup', this.onMouseUp.bind(this));
            this.$enabledArea.on('mouseleave', this.onMouseLeave.bind(this));
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new CustomCursor(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    CursorControl.init({
        selector: "[data-custom-cursor]",
    });
})(window.jQuery);
